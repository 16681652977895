.mySwiper .swiper-slide {
  border-radius: 8px !important; /* Swiper 슬라이드의 테두리를 둥글게 */
}

.mySwiper img {
  border-radius: 8px !important; /* 이미지의 테두리를 둥글게 */
}

/* 전체 앱 컨테이너 */
.app-container {
  width: 100%;
  padding: 0 20px; /* 기본 padding */
  box-sizing: border-box;
}

/* 화면이 일정 너비 이상일 때 패딩과 고정 너비 적용 */
@media (min-width: 1200px) {
  .app-container {
    max-width: 1200px; /* 최대 너비 고정 */
    margin: 0 auto; /* 가운데 정렬 */
    padding: 0 40px; /* 좌우 패딩 */
  }
}

@font-face {
  font-family: 'MBC1961GulimM';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-01@1.0/MBC1961GulimM.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}