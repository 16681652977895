.ql-editor {
  font-size: 15px;
  padding: 12px !important;
}
.mention {
    color: #0366d6;
    background: rgba(3, 102, 214, 0.1);
    padding: 2px 4px;
    border-radius: 4px;
  }
  
  .hashtag {
    color: #1173FF;
    background: rgba(29, 161, 242, 0.1);
    padding: 2px 4px;
    border-radius: 4px;
  }
  
  .ql-mention-list-container {
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin-top: 5px;
  }
  
  .ql-mention-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .ql-mention-list-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .ql-mention-list-item.selected {
    background: #e8f0fe;
  }

  .ql-hashtag {
    color: #1976d2;
    font-weight: bold;
    cursor: pointer;
  }
  
  .ql-hashtag:hover {
    text-decoration: underline;
  }

  .ql-bubble .ql-editor {
    padding: 0 !important;
  }